.projects-section {

    .project:not(:last-child) {
        margin-bottom: 500px;
    }

    @media only screen and (min-width: 2000px) {
        .project:not(:last-child) {
            margin-bottom: 1000px;
        }
    }

    .project:last-child {
        margin-bottom: 300px;
    }

    .project {

        &__summary {
            position: relative;

            &--large-pulse {
                position: absolute;
                z-index: -1;
                left: 0;
                margin: 0;
                width: 60%;
            }
    
            &--small-pulse {
                position: absolute;
                right: 0;
                z-index: -1;
                margin: 0;
                width: 40%;
            }

            &--details {
                width: 100%;
                min-width: 250px;
                border-radius: 30px;
                margin: 10px;
                align-items: flex-start;

                &__fig-and-icons {
                    width: 40%;
                    margin: 20px;
                    &--fig {
                        width: 100%;
                        margin: 0;

                        & img {
                            border-radius: 20px;
                        }
                    }

                    &--icons {
                        margin: 0 auto;
                        margin-top: 20px;
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;

                        &__icon {
                            width: 15%;
                            min-width: 50px;
                            border-radius: 10px;
                            // border: 3px solid #000;
                            border: 3px solid rgb(180, 180, 180);
                            // background-color: rgba(37, 37, 37, 0.726);
                            background-color: rgba(156, 156, 156, 0.726);
                            margin: 10px;
        
                            & img {
                                width: 50%;
                            }
                        }

                        &__icon:after {
                            content: "";
                            display: block;
                            padding-bottom: 100%;
                        }
                    }
                }

                &__desc {
                    width: 40%;
                    min-width: 250px;
                    margin: 20px;
                    text-align: justify;
                    font-size: 1.3vw;

                    &--title {
                        font-size: 1.9vw;
                        margin-top: 0;
                    }
                        
                }
            }
        }


        &__details {
            width: 60%;
            text-align: left;

            &--title {
                font-size: 1.8vw;
            }
        }
    }


    // Mobile styling
    @media only screen and (max-width: 600px) {
        .project:not(:last-child) {
            margin-bottom: 100px;
        }
    
        .project:last-child {
            margin-bottom: 100px;
        }

        .project {
            width: 100%;

            &__summary {
                width: 100%;
                overflow: hidden;

                &--large-pulse {
                    width: 160%;
                    left: -200px;

                    & img {
                        width: 90%;
                    }

                }

                &--small-pulse {
                    width: 160%;
                    right: -200px;

                    & img {
                        width: 90%;
                    }
                }

                &--details {
                    margin: 0;
                    &__desc {
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                        text-align: left;
                        font-size: 1.1rem;

                        &--btn-container {
                            width: 100%;

                            & .btn {
                                width: 100%;
                            }
                        }

                        &--title {
                            font-size: 1.6rem;
                        }
                    }
                    
                    &__fig-and-icons {
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;

                        &--fig {
                            & img {
                                border-radius: 10px;
                            }
                        }

                        &--icons {
                            width: 100%;

                            &__icon {
                                margin: 5px;
                                border-width: 2px;
                                border-radius: 8px;
                            }
                        }
                    }
                }

            }
        }
    }

    // Ipad styling
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        .project:not(:last-child) {
            margin-bottom: 300px;
        }
    
        .project:last-child {
            margin-bottom: 300px;
        }

        .project {
        
            &__summary {

                &--large-pulse {
                    position: absolute;
                    z-index: -1;
                    left: 0;
                    margin: 0;
                    width: 55%;
                }
        
                &--small-pulse {
                    position: absolute;
                    right: 0;
                    z-index: -1;
                    margin: 0;
                    width: 35%;
                }

                &--details {

                    &__desc {

                        &--btn-container {
                            width: 100%;
                            justify-content: center;
                            & .btn {
                                flex: 1;
                                padding: 20px 10px;
                                margin: 10px;
                            }

                            & .wider-btn {
                                flex: none;
                                width: 70%;
                            }
                        }
                    }
                }
            }
        }
    }

    // Mobile styling
    @media only screen and (min-width: 2000px) {

        .project {
            width: 100%;

            &__summary {

                &--details {
                    
                    &__fig-and-icons {

                        &--icons {

                            &__icon {
                                border-width: 4px;
                                border-radius: 15px;
                            }
                        }
                    }
                }

            }
        }
    }
}