.end-section {

    &__btn-container {
        width: 80%;
        margin-top: 100px;

        .btn {
            background-color: #E6E6E6;
            box-shadow: 1px 4px 1px #818181;
            margin: 20px;
            width: 35%;
        }

        .alternate-btn {
            background-color: #808080;
            box-shadow: 1px 4px 1px #505050;
        }

        &--btn {
            min-width: 200px;
            height: 7vw;

            & figure {
                width: 100%;
                height: 100%;
                margin: 0;
            }

            & img {
                height: 80%;
            }
        }
    }

    @media only screen and (max-width: 745px) {
        margin-top: 200px;
    
        &__btn-container {
            width: 90%;
            margin-top: 100px;
    
            &--btn {
                width: 70%;
                height: 7vh;
            }
        }
    }

    @media only screen and (min-width: 2000px) {
        margin-top: 500px;

        &__logo {
            width: 10vh;
            & img {
                width: 100%;
            }
        }
    
        &__btn-container {
            width: 70%;
            margin-top: 100px;
    
            &--btn {
                width: 70%;
                height: 10vh;
            }
        }
    }
}
