html {
  background-color: #121212;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  position: relative;
  color: white;
  font-family: Lato;
}

h1, h2, h3, h4, h5 {
  font-family: Courgette;
}

h1 {
  font-weight: normal;
}

.italic-text {
  font-style: italic;
}

.responsive-img {
  width: 100%;
  height: auto;
}

.center-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.clickable {
  cursor: pointer;
}

// Better spacing for items on larger screens
@media only screen and (min-width: 2000px) {
  .lg-space-around {
    justify-content: space-around;
  }
}

// Mobile specific row and column config
@media only screen and (max-width: 600px) {
  .mobile-reverse-row {
    flex-direction: row-reverse;
  }
  
  .mobile-reverse-col {
    flex-direction: column-reverse;
  }
}

.test-credentials {
  border-left: 4px solid white;
  width: auto;
  padding: 10px;
  margin-bottom: 20px;

  & h4, p {
    margin: 0;
  }
}

/* Buttons */

.btn {
  width: 35%;
  padding: 20px;
  margin: 10px;
  cursor: pointer;
  border-radius: 10px;
  border: none;
  background-color: #75A8EA;
  box-shadow: 1px 4px 1px #375e91;
  transition: all .2s ease-in-out;
  text-align: center;
  color: black;
  text-decoration: none;
  font-family: Lato;
}

.btn:hover {
  transform: scale(1.05);
}

.btn:active {
  transform: scale(0.95);
}

.alternate-btn {
  background-color: #364D6B;
  box-shadow: 1px 4px 1px #213042;
  color: white;
}

.main-btn-blue {
  background-color: #75A8EA;
  box-shadow: 1px 4px 1px #375e91;
}

.alternate-btn-blue {
  background-color: #334966;
  box-shadow: 1px 4px 1px #1d2d42;
  color: white;
}

.main-btn-orange {
  background-color: #FFA166;
  box-shadow: 1px 4px 1px #c46e39;
}

.alternate-btn-orange {
  background-color: #7F390D;
  box-shadow: 1px 4px 1px #5c2503;
  color: white;
}

.wider-btn {
  width: 55%;
}

@media only screen and (min-width: 2000px) {
  .btn {
    padding: 50px;
    box-shadow: 1px 8px 1px #375e91;
  }

  .alternate-btn {
    box-shadow: 1px 8px 1px #213042;
  }

  .main-btn-blue {
    box-shadow: 1px 4px 1px #375e91;
  }
  
  .alternate-btn-blue {
    box-shadow: 1px 4px 1px #1d2d42;
  }
  
  .main-btn-orange {
    box-shadow: 1px 4px 1px #c46e39;
  }
  
  .alternate-btn-orange {
    box-shadow: 1px 4px 1px #5c2503;
  }
}

/* Section Containers */
.section {
  margin-bottom: 200px;
}

@media only screen and (max-width: 600px) {
  .section {
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 2000px) {
  .section {
    margin-bottom: 400px;
  }
}

.section-heading {
  font-size: 2.5vw;
  margin-top: 20px;
  margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
  .section-heading {
    font-size: 1.7rem;
  }
}

@media only screen and (min-width: 2000px) {
  .section-heading {
    margin-bottom: 100px;
  }
}

/* Hide and show elements on mobile */
.show-on-mobile {
  display: none;
}

@media only screen and (max-width: 600px) {
  .show-on-mobile {
    display: block;
  }

  .hide-on-mobile {
    display: none;
  }
}