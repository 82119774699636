.about-me {

    &__info {
        width: 80%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;

        &--icon-container {
            min-width: 100px;

            & img {
                width: auto;
                height: 80px;
            }
        }
    }

    /* Large Screen Styling */
    @media only screen and (min-width: 2000px) {
        &__info {

            &--icon-container {
                width: 30%;
                font-size: 1.5vw;

                &__icon {
                    margin: 10px;

                    & img {
                        height: 150px;
                    }
                }
            }
        }
    }

    /* Mobile Styling */
    @media only screen and (max-width: 600px) {
        &__info {
            
            width: 90%;

            &--icon-container {
                width: 20%;
                font-size: 0.8rem;

                &__icon {
                    margin: 10px;

                    & img {
                        height: 50px;
                    }
                }
            }
        }
    }
}
