.contact-me {

    &__orange-btn {
        background-color: #C46D35;
        box-shadow: 1px 4px 1px #813d12;
    }

    &__blue-btn {
        background-color: #2F7EE6;
        box-shadow: 1px 4px 1px #0d4896;
    }

    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 400px;
    margin-bottom: 300px;

    @media only screen and (min-width: 2000px) {
        margin-top: 1000px;
    }

    &__large-pulse {
        width: 17%;
        margin: 0;
        position: absolute;
        left: 0;
    }

    &__small-pulse {
        width: 12%;
        margin: 0;
        position: absolute;
        right: 0;
    }

    &__title-and-btn {
        width: 80%;
        margin: 10px;
        justify-content: space-around;

        &--title {
            margin-bottom: 50px;
        }

        &--mail-btn {
            width: 30%;
            padding: 20px;
            margin: 10px;
            cursor: pointer;
            border-radius: 10px;
            border: none;
            transition: all .2s ease-in-out;
            text-align: center;
            color: #121212;
            text-decoration: none;
            font-size: 1.3vw;
        }

        @media only screen and (min-width: 2000px) {
            &--title {
                font-size: 1.7vw;
            }

            &--mail-btn {
                padding: 50px;
            }
        }

        &--mail-btn:hover {
            transform: scale(1.05);
          }
          
          &--mail-btn:active {
        transform: scale(0.95);
        }
    }

    @media only screen and (max-width: 600px) {
        width: 100%;
        margin-top: 200px;
        margin-bottom: 100px;

        &__large-pulse {
            width: 45%;
            margin: 0;
            position: absolute;
            left: 0;
            z-index: -5;
        }
    
        &__small-pulse {
            width: 40%;
            margin: 0;
            position: absolute;
            right: 0;
            z-index: -5;
        }

        &__title-and-btn {
    
            &--mail-btn {
                width: 65%;
                font-size: 1.1rem;
            }
        }
    }
}