.product-areas {
    
    &__all-logos {
        width: 100%;
        flex-wrap: wrap;
        align-items: flex-end;

        &--logo-container {
            width: 20%;
            min-width: 100px;
            max-width: 200px;
            margin: 10px;
    
            &__logo {
                width: 100%;
                margin: 0;

                & img {
                    width: 50%;
                }
            }
        }
    }

    flex-wrap: wrap;

    /* Large Screen Styling */
    @media only screen and (min-width: 2000px) {
        &__all-logos {
            width: 80%;
    
            &--logo-container {
        
                &__logo {
    
                    & img {
                        width: 80%;
                    }
                }

                & p {
                    font-size: 1.5vw;
                }
            }
        }
    }

}