.header {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;

    &__intro {
        width: 50%;
        margin-left: 50px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &--profile-pic {
            width: 40%;
            margin: 0 0 10px 0;

            & img {
                width: 100%;
            }
        }

        &--name {
            font-size: 5vw;
            margin: 10px 0;
        }

        &--desc {
            font-size: 2.5vw;
            width: 70%;
        }

        &--btn-container {
            width: 100%;
        }

        &--btn {
            width: 30%;
            height: 10vh;

            & figure {
                width: 100%;
                height: 100%;
                margin: 0;
            }

            & img {
                height: 80%;
            }
        }
    }

    &__pulse {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        width: 70%;
        z-index: -1;
    }

    &__pulse-mobile {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        width: 100%;
        z-index: -1;
    }

    @media only screen and (max-width: 600px) {

        align-items: center;

        &__intro {
            width: 100%;
            margin: 0;

            & .btn {
                width: 30%;
                height: 8vh;
            }

            &--name {
                font-size: 2.5rem;
                margin: 10px 0;
            }
    
            &--desc {
                font-size: 1.5rem;
                width: 70%;
            }

            &--btn-container {
                width: 100%;
            }

            &--btn {
                width: 30%;
            }
        }

        &__pulse {
            width: 100%;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
        height: 60vh;
    }
}

